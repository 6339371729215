import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faSyncAlt } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import Layout from "../components/layout"
import Box from "../images/box.png"
import { Link } from "gatsby"

import { useRandomNextCard } from "../hooks/dilemmas"

const GameOverPage = () => {
  const [nextCard] = useRandomNextCard(null)

  return (
    <Layout>
      <div className="container mx-auto px-8 lg:flex items-center py-20 lg:py-32 ">
        <div className="lg:w-7/12 lg:order-2 sm:max-w-md lg:max-w-6xl mx-auto"><img src={Box} /></div>
        <div className="lg:w-5/12 flex flex-col items-center text-center lg:items-start lg:text-left">
          <h2 className="text-lg md:text-2xl font-bold text-yellow">Game over &mdash; no more dilemmas</h2>
          <h1 className="text-2xl md:text-5xl font-bold leading-none">Free card game with an AppSignal trial 💥</h1>
          <p className="lg:text-xl text-gray-300 my-4">If you liked this digital game, you'll love the physical version! If you try AppSignal's application monitoring (Ruby, Node.js, Elixir or JS), we'll send you a free card game. Worldwide shipping 🌏! </p>
          <div>
            <a href="https://appsignal.com/?utm_source=developerdilemmas&utm_medium=website&utm_campaign=developerdilemmas-gameover" className="c-button c-button--white my-4 mr-4">
              Try AppSignal
              <FontAwesomeIcon icon={faArrowRight} className="c-button__icon ml-2" />
            </a>
            <Link to={nextCard.path} className="c-button c-button--gray mr-4">
              Play again!
              <FontAwesomeIcon icon={faSyncAlt} className="c-button__icon ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
)
}

export default GameOverPage
